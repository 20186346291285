import { cloneDeep } from 'lodash';
import {
  SET_ASSETS_TO_PREVIEW,
  UPDATE_ASSET_TO_PREVIEW,
} from './actionsTypes';

const initState = {
  assetsToPreview: [],
  isAssetsFromGameStorage: false,
  isAssetsFromCollection: false,
};

const assetsToPreviewReducer = (state = initState, action) => {
  const { type, payload } = action;

  switch (type) {

    case SET_ASSETS_TO_PREVIEW:
      return payload;

    case UPDATE_ASSET_TO_PREVIEW: {
      const  {
        assetId,
        newAssetFields,
      } = payload;

      const newAssets = cloneDeep(state.assetsToPreview).map((asset) => {
        if (asset.id === assetId) {
          return {
            ...asset,
            ...newAssetFields,
          };
        } else {
          return asset;
        }
      });

      return {
        ...state,
        assetsToPreview: newAssets,
      };
    }
    default:
      return state;
  }
};

export default assetsToPreviewReducer;