import {
  SET_ASSETS_TO_PREVIEW,
  UPDATE_ASSET_TO_PREVIEW,
} from './actionsTypes';


export const setAssetsToPreviewAction = (
  assetsToPreview,
  isAssetsFromGameStorage = false,
  isAssetsFromCollection = false
) => {
  return {
    type: SET_ASSETS_TO_PREVIEW,
    payload: {
      assetsToPreview,
      isAssetsFromGameStorage,
      isAssetsFromCollection,
    },
  };
};

export const updateAssetToPreviewAction = (assetId, newAssetFields) => {
  return {
    type: UPDATE_ASSET_TO_PREVIEW,
    payload: {
      assetId,
      newAssetFields,
    },
  };
};