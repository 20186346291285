import React from 'react';
import { useDrag } from 'react-dnd';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import EntityPreview from '../../Parts/EntityPreview';
import VideoPlayOverlay from 'components/UIComponents/VideoPlayOverlay';
import EntityOverlay from '../../Parts/EntityOverlay';
import CustomCheckbox from 'components/UIComponents/CustomCheckbox';
import EntityGridInfo from '../../Parts/EntityGridInfo';
import EntityDoNotUseOverlay from '../../Parts/EntityDoNotUseOverlay';
import {
  stringOrNumberPropType,
  stringOrNumberPropTypeRequired,
} from 'types/general';
import { ASSET_DRAG_AND_DROP_ACCEPT_TYPE } from 'constants/Asset';

import ZoomInIcon from '@material-ui/icons/ZoomIn';

import './FileGridItem.scss';


const FileGridItem = ({
  id,
  name,
  extension,
  gameName,
  jiraIssueKey,
  jiraTicketLink,
  weight,
  live,
  approved,
  isError,
  isSelected,
  previewUrl,
  hasPreview,
  hasVideoPreview,
  toggleSelection,
  handleGameClick,
  userCanManage,
  dragAndDropAcceptType,
  openFileInfo,
}) => {

  const [, drag] = useDrag(() => ({
    type: dragAndDropAcceptType || ASSET_DRAG_AND_DROP_ACCEPT_TYPE.GAME,
    item: { id, name, isFolder: false, extension },
  }));

  return (
    <div
      id={id}
      className={classnames('file-grid-item', {
        'error': isError,
      })}
      ref={userCanManage ? (element) => drag(element) : null}
    >
      <div className='file-grid-item__preview'>
        <EntityOverlay isActive={isSelected}>
          <CustomCheckbox
            checkedCondition={isSelected}
            handleCheck={toggleSelection}
            transparent
          />

          {openFileInfo &&
            <div
              className='file-grid-item__info-icon-container'
              onClick={openFileInfo}
            >
              <ZoomInIcon className='file-grid-item__info-icon' />
            </div>
          }
        </EntityOverlay>

        <EntityPreview
          previewUrl={previewUrl}
          hasPreview={hasPreview}
          alt={'File preview'}
        />

        {hasVideoPreview &&
          <VideoPlayOverlay className='file-grid-item__video-play-icon' />
        }

        <div className='file-grid-item__badges-container'>
          <div
            className={classnames('file-grid-item__badge extension', {
              'hidden': !extension,
            })}
          >
            <p className='file-grid-item__badge-text'>
              {extension}
            </p>
          </div>
        </div>

        {!approved &&
          <EntityDoNotUseOverlay iconFontSize={52} />
        }
      </div>

      <EntityGridInfo
        live={live}
        title={name}
        subtitle={gameName}
        footerLeftInfo={jiraIssueKey}
        footerRightInfo={weight}
        jiraTicketLink={jiraTicketLink}
        handleSubtitleClick={handleGameClick}
      />
    </div>
  );
};

FileGridItem.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  extension: PropTypes.string,
  gameId: stringOrNumberPropTypeRequired,
  gameName: PropTypes.string.isRequired,
  jiraIssueKey: PropTypes.string,
  jiraTicketLink: PropTypes.string,
  weight: stringOrNumberPropType,
  live: PropTypes.bool,
  approved: PropTypes.bool,
  isError: PropTypes.bool,
  isSelected: PropTypes.bool,
  previewUrl: PropTypes.string,
  hasPreview: PropTypes.bool,
  hasVideoPreview: PropTypes.bool,
  toggleSelection: PropTypes.func.isRequired,
  handleGameClick: PropTypes.func,
  userCanManage: PropTypes.bool,
  dragAndDropAcceptType: PropTypes.string,
  openFileInfo: PropTypes.func,
};

export default FileGridItem;
