import React, { useState, Suspense } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Redirect, Switch, Route } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import Dropzone from 'react-dropzone';
import Preloader from '../../UIComponents/Preloader';
import LeftSidebar from '../../Navigation/LeftSidebar';
import AssetUploader from '../../AssetUploader';
import DeleteAssets from '../../Assets/DeleteAssets';
import CollectionsModal from '../../Collections/CollectionsModal';
import AssetUploaderContext from '../../AssetUploader/assetUploaderContext';
import CustomerSupportDashboard from '../../CustomerSupport/CustomerSupportDashboard';
import { checkIfExternalUser } from '../../General/Permissions/Permission';
import {
  DEPARTMENT_PERMISSION,
  SIDEBAR_SECTIONS,
} from 'constants/General';
import { dashboardIds } from 'constants/Retool';
import { getExtensionIconSrc } from 'utils/utils';

/**
 * Route components
 */
import Creative from '../../Creative';
import Sharing from '../../Sharing';
import AnalyticsDevice from '../../AnalyticsDevice';
import AnalyticsEvent from '../../AnalyticsEvent';
import GameSettings from '../../GameSettings';
import Retool from '../../General/Retool';
import NotFound from '../NotFound';

/**
 * Lazy load route components
 */
const Home = React.lazy(() => import('../../Home'));
const MainSearch = React.lazy(() => import('../../MainSearch'));
const Collections = React.lazy(() => import('../../Collections'));
const Collection = React.lazy(() => import('../../Collections/Collection'));
const ExternalUsersLogin = React.lazy(() => import('../../ExternalUsersLogin'));
const CreativeTools = React.lazy(() => import('../../Laboratory/MainPage'));
const VideoEditor = React.lazy(() => import('../../Laboratory/VideoEditor'));
const Randomizer = React.lazy(() => import('../../Laboratory/Randomizer'));
const AdminPanelCreativeTools = React.lazy(() => import('../../Laboratory/AdminPanel'));
const UnitCost = React.lazy(() => import('../../CreativeDashboard/UnitCost'));
const Dashboard = React.lazy(() => import('../../CreativeDashboard/Dashboard'));
const PodCapacity = React.lazy(() => import('../../CreativeDashboard/PodCapacity'));
const DepartmentWorkload = React.lazy(() => import('../../CreativeDashboard/DepartmentWorkload'));
const StaffAllocation = React.lazy(() => import('../../CreativeDashboard/StaffAllocation'));
const AdCreate = React.lazy(() => import('../../Laboratory/AdCreate'));

import styles from './styles.module.scss';


const Main = ({ location }) => {

  const { assetsToDeleteIds } = useSelector((state) => state.assetsToDelete);
  const {
    assetsIds,
    isCollectionCreationState,
    collectionToEdit,
  } = useSelector((state) => state.collections);

  const {
    isFetching,
    userJiraProjectIds,
    profile,
  } = useSelector((state) => state.user) || {};

  const {
    googleId,
    externalPermission,
  } = profile;

  const wasLoaded = profile.id !== null;
  const userDepartment = profile.department.name;

  // Permissions
  const isUserExternal = checkIfExternalUser();

  const [assetsToUpload, setAssetsToUpload] = useState([]);

  const checkIfUserHaveAccessToAtLeastOneGame = () => {
    if (isUserExternal) {
      return !!profile.externalPermission.games.filter((game) => game.access.upload).length;
    } else {
      return !!userJiraProjectIds.length;
    }
  };

  const handleSetAssetsToUpload = (files) => {
    const newAssetsToUploadPathes = [];
    const newAssetsToUpload = files.map((file) => {
      const path = file.path ? file.path.split('/').slice(1, -1).join('/') : '';
      newAssetsToUploadPathes.push(file.name + path);
      return {
        file,
        id: uuidv4(),
        name: file.name,
        path,
        iconPreview: getExtensionIconSrc(file.name.split('.').pop()),
        category: null,
        subCategory: null,
        productionFormat: null,
        developerFormat: null,
        typeCat: null,
        tags: [],
        description: '',
      };
    });

    const filteredOldAssetsToUpload = assetsToUpload.filter((asset) => !newAssetsToUploadPathes.includes(asset.name + asset.path));

    setAssetsToUpload([
      ...filteredOldAssetsToUpload,
      ...newAssetsToUpload,
    ]);
  };

  const checkIfUserHasAccessToTool = (toolName) => {
    const arrayOfTools = externalPermission ? Object.entries(externalPermission.tools) : [];
    return !googleId || arrayOfTools.some((tool) => tool[0] === toolName && tool[1]);
  };

  const canUploadAssets = checkIfUserHaveAccessToAtLeastOneGame();

  const redirectUrl = userDepartment === DEPARTMENT_PERMISSION.CUSTOMER_SUPPORT
    ? '/cs-dash'
    : '/home';

  const routsRestrictedForExternalUser = (
    <Switch>
      <Route
        path='/game-settings'
        component={GameSettings}
      />

      <Route
        path='/cs-dash'
        component={CustomerSupportDashboard}
      />

      <Route
        exact
        path='/external-users-login'
      >
        <Suspense fallback={<Preloader />}>
          <ExternalUsersLogin />
        </Suspense>
      </Route>

      <Route
        exact
        path='/format-estimates'
      >
        <Suspense fallback={<Preloader />}>
          <UnitCost />
        </Suspense>
      </Route>

      <Route
        exact
        path='/dashboard'
      >
        <Suspense fallback={<Preloader />}>
          <Dashboard />
        </Suspense>
      </Route>

      <Route
        exact
        path='/pod-capacity'
      >
        <Suspense fallback={<Preloader />}>
          <PodCapacity />
        </Suspense>
      </Route>

      <Route
        exact
        path='/department-workload'
      >
        <Suspense fallback={<Preloader />}>
          <DepartmentWorkload />
        </Suspense>
      </Route>

      <Route
        exact
        path='/staff-allocation'
      >
        <Suspense fallback={<Preloader />}>
          <StaffAllocation />
        </Suspense>
      </Route>

      <Route
        exact
        path='/collections'
      >
        <Suspense fallback={<Preloader />}>
          <Collections canUploadAssets={canUploadAssets} />
        </Suspense>
      </Route>

      <Route
        exact
        path='/collections/:collectionId'
      >
        <Suspense fallback={<Preloader />}>
          <Collection canUploadAssets={canUploadAssets} />
        </Suspense>
      </Route>

      <Route
        path='*'
        component={NotFound}
      />
    </Switch>
  );

  return (
    <div className={styles.mainContainer}>
      <LeftSidebar currentRoute={location.pathname} />

      <Dropzone
        multiple
        noClick
        disabled={!canUploadAssets}
        onDropAccepted={handleSetAssetsToUpload}
      >
        {({ getRootProps, getInputProps, open }) => (
          <AssetUploaderContext.Provider value={open}>
            <div
              className={styles.contentContainer}
              id='main-scrollable-component'
              {...getRootProps()}
            >
              {isFetching && !wasLoaded
                ? <Preloader />
                : (
                  <Switch>
                    <Route
                      exact
                      path='/home'
                    >
                      <Suspense fallback={<Preloader />}>
                        <Home />
                      </Suspense>
                    </Route>

                    <Route
                      exact
                      path='/search'
                    >
                      <Suspense fallback={<Preloader />}>
                        <MainSearch canUploadAssets={canUploadAssets} />
                      </Suspense>
                    </Route>

                    <Route
                      path='/creatives'
                      component={Creative}
                    />

                    <Route
                      path='/share-assets'
                      component={Sharing}
                    />

                    <Route
                      exact
                      path={'/collection-share/:collectionId/:collectionHash'}
                    >
                      <Suspense fallback={<Preloader />}>
                        <Collection canUploadAssets={canUploadAssets} />
                      </Suspense>
                    </Route>

                    <Route
                      path='/data-tools'
                      component={() => checkIfUserHasAccessToTool(SIDEBAR_SECTIONS.DATA_TOOLS) ?
                        <Retool dashboardId={dashboardIds.dataTools} /> :
                        <Redirect to={'/'} />
                      }
                    />

                    <Route
                      path='/analytics-device'
                      component={() => checkIfUserHasAccessToTool(SIDEBAR_SECTIONS.QA_TOOL)  ?
                        <AnalyticsDevice /> :
                        <Redirect to={'/'} />
                      }
                    />

                    <Route
                      path='/analytics-event'
                      component={() => checkIfUserHasAccessToTool(SIDEBAR_SECTIONS.QA_TOOL)  ?
                        <AnalyticsEvent /> :
                        <Redirect to={'/'} />
                      }
                    />

                    <Route
                      path='/spongebob-admin'
                      component={() => checkIfUserHasAccessToTool(SIDEBAR_SECTIONS.CUSTOMER_SUPPORT_TOOL) ?
                        <Retool dashboardId={dashboardIds.spongebob} /> :
                        <Redirect to={'/'} />
                      }
                    />

                    <Route
                      path='/retool'
                      component={() => checkIfUserHasAccessToTool(SIDEBAR_SECTIONS.CUSTOMER_SUPPORT_TOOL) ?
                        <Retool dashboardId={dashboardIds.retool} /> :
                        <Redirect to={'/'} />
                      }
                    />

                    <Route
                      exact
                      path='/creative-tools'
                    >
                      <Suspense fallback={<Preloader />}>
                        {checkIfUserHasAccessToTool(SIDEBAR_SECTIONS.CREATIVE_TOOLS) ?
                          <CreativeTools /> :
                          <Redirect to={'/'} />
                        }
                      </Suspense>
                    </Route>

                    <Route
                      exact
                      path='/creative-tools/video-editor/:projectId'
                    >
                      <Suspense fallback={<Preloader />}>
                        {checkIfUserHasAccessToTool(SIDEBAR_SECTIONS.CREATIVE_TOOLS) ?
                          <VideoEditor /> :
                          <Redirect to={'/'} />
                        }
                      </Suspense>
                    </Route>

                    <Route
                      exact
                      path='/creative-tools/randomizer'
                    >
                      <Suspense fallback={<Preloader />}>
                        {checkIfUserHasAccessToTool(SIDEBAR_SECTIONS.CREATIVE_TOOLS) ?
                          <Randomizer /> :
                          <Redirect to={'/'} />
                        }
                      </Suspense>
                    </Route>

                    <Route
                      exact
                      path='/creative-tools/admin-panel'
                    >
                      <Suspense fallback={<Preloader />}>
                        {checkIfUserHasAccessToTool(SIDEBAR_SECTIONS.CREATIVE_TOOLS) ?
                          <AdminPanelCreativeTools /> :
                          <Redirect to={'/'} />
                        }
                      </Suspense>
                    </Route>

                    <Route
                      exact
                      path='/creative-tools/ad-create/:projectId'
                    >
                      <Suspense fallback={<Preloader />}>
                        {checkIfUserHasAccessToTool(SIDEBAR_SECTIONS.CREATIVE_TOOLS) ?
                          <AdCreate /> :
                          <Redirect to={'/'} />
                        }
                      </Suspense>
                    </Route>

                    <Redirect
                      exact
                      from='/'
                      to={redirectUrl}
                    />

                    {!isUserExternal ?
                      routsRestrictedForExternalUser :
                      <Route
                        path='*'
                        component={NotFound}
                      />
                    }
                  </Switch>
                )}
            </div>

            {!!assetsToUpload.length &&
              <AssetUploader
                assetsToUpload={assetsToUpload}
                setAssetsToUpload={setAssetsToUpload}
              />
            }
          </AssetUploaderContext.Provider>
        )}
      </Dropzone>

      {!!assetsToDeleteIds.length &&
        <DeleteAssets />
      }

      {(!!assetsIds.length || isCollectionCreationState || !!collectionToEdit) &&
        <CollectionsModal />
      }
    </div>
  );
};

Main.propTypes = {
  location: PropTypes.object.isRequired,
};

export default Main;
