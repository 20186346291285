import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import Selecto from 'react-selecto';
import * as queryString from 'query-string';
import classnames from 'classnames';
import ButtonWithCheckbox from 'components/UIComponents/ButtonWithCheckbox';
import SortingButton from 'components/UIComponents/SortingButton';
import GridListViewPanel from 'components/UIComponents/GridListViewPanel';
import RoundModalWithInput from 'components/UIComponents/RoundModalWithInput';
import ConfirmRoundModal from 'components/UIComponents/ConfirmRoundModal';
import EmptyState from 'components/UIComponents/EmptyState';
import Preloader from 'components/UIComponents/Preloader';
import FailRequestScreen from 'components/UIComponents/FailRequestScreen';
import CollectionStorageNavigation from './CollectionStorageNavigation';
import CollectionStorageResults from './CollectionStorageResults';
import CollectionsActions from '../../CollectionsActions';
import {
  createCollectionFolderAction,
  editCollectionFolderAction,
  deleteCollectionAssetsAction,
  moveCollectionAssetsAction,
  getCollectionAssetsAction,
  presetCollectionIdAction,
} from '../../Collections.redux/Actions/collectionActions';
import CollectionAssetsActions from './CollectionAssetsActions';
import { setAssetsToPreviewAction } from 'components/Assets/AssetsDetailsPreview/AssetsDetailsPreview.redux/actions';
import { handleSortEntities } from 'components/MainSearch/mainSearchHelpers';
import {
  ENTITIES_SORT_OPTIONS,
  MAIN_SEARCH_SORTING_LOCAL_STORAGE_KEY,
  MAIN_SEARCH_VIEW_TYPE_LOCAL_STORAGE_KEY,
  ROOT_DIR_PATH,
} from 'components/MainSearch/mainSearchConstants';
import { SEARCH_CATEGORY_ENGINES } from 'components/MainSearch/mainSearchConfig';
import { VIEW_GRID } from 'constants/General';
import { VALIDATION } from 'constants/Validation';
import { collectionPropTypes } from '../../Types';

import {
  CreateNewFolder as CreateNewFolderIcon,
  Add as AddIcon,
  Edit as EditIcon,
} from '@material-ui/icons';

import './CollectionStorage.scss';


const CollectionStorage = ({ isPrivate, collection, searchValue }) => {

  const dispatch = useDispatch();
  const history = useHistory();
  const collectionStorageRef = useRef(null);
  const { collectionHash } = useParams();

  const {
    id,
    name,
    owner,
  } = collection || {};

  const {
    loading,
    data: assets = [],
    error,
  } = useSelector((state) => state.collections.collectionAssets);

  const { id: userId } = useSelector((state) => state.user?.profile);
  const { loading: manageAssetsLoading } = useSelector((state) => state.collections.manageCollectionAssets);

  const [viewType, setViewType] = useState(localStorage.getItem(MAIN_SEARCH_VIEW_TYPE_LOCAL_STORAGE_KEY) || VIEW_GRID);
  const [activeSortOption, setActiveSortOption] = useState(JSON.parse(localStorage.getItem(MAIN_SEARCH_SORTING_LOCAL_STORAGE_KEY)) || ENTITIES_SORT_OPTIONS[0]);

  const [isNewFolderModal, setIsNewFolderModal] = useState(false);
  const [folderToRename, setFolderToRename] = useState(null);
  const [isDeleteAssetsModal, setIsDeleteAssetsModal] = useState(false);

  const [selectedAssetIdsSet, setSelectedAssetIdsSet] = useState(new Set());

  const folderFromQuery = useMemo(() => queryString.parse(history.location.search),[history.location.search]);
  const isLegitFolder = useMemo(() => Boolean(folderFromQuery?.id && folderFromQuery?.name),[folderFromQuery]);

  const canManageCollection = useMemo(() => owner?.id === userId, [userId, owner?.id]);
  const sortedAssets = useMemo(() => assets ? handleSortEntities(assets, activeSortOption) : [], [assets, activeSortOption.id]);
  const sortedAndFilteredAssets = useMemo(() => sortedAssets.filter((sortedAsset) => sortedAsset.name.toLowerCase().includes(searchValue.toLowerCase())), [sortedAssets, searchValue]);
  const folders = useMemo(() => sortedAndFilteredAssets.filter((asset) => asset.isFolder), [sortedAndFilteredAssets]);
  const files = useMemo(() => sortedAndFilteredAssets.filter((asset) => !asset.isFolder), [sortedAndFilteredAssets]);
  const selectedAssets = useMemo(
    () => sortedAndFilteredAssets.filter((asset) => selectedAssetIdsSet.has(asset.entityId)),
    [sortedAndFilteredAssets, selectedAssetIdsSet]
  );

  const emptyStateText = useMemo(() => {
    if (searchValue) {
      return 'Oops! Nothing was found';
    } else {
      if (canManageCollection) {
        return 'You haven’t added any assets yet';
      } else {
        return 'Collection owner hasn’t added assets yet';
      }
    }
  }, [searchValue, canManageCollection]);

  useEffect(() => {
    setSelectedAssetIdsSet(new Set());
  }, [searchValue]);

  const toggleViewType = (viewType) => {
    setViewType(viewType);
    localStorage.setItem(MAIN_SEARCH_VIEW_TYPE_LOCAL_STORAGE_KEY, viewType);
  };

  const handleChangeActiveSortOption = (sortOption) => {
    setActiveSortOption(sortOption);
    localStorage.setItem(MAIN_SEARCH_SORTING_LOCAL_STORAGE_KEY, JSON.stringify(sortOption));
  };

  const checkIfAllAssetsSelected = () => {
    return selectedAssetIdsSet.size && assets.every((asset) => selectedAssetIdsSet.has(asset.entityId));
  };

  const toggleSelectAllAssets = () => {
    if (checkIfAllAssetsSelected()) {
      assets.forEach((asset) => selectedAssetIdsSet.delete(asset.entityId));
      setSelectedAssetIdsSet(new Set(selectedAssetIdsSet));
    } else {
      assets.forEach((asset) => selectedAssetIdsSet.add(asset.entityId));
      setSelectedAssetIdsSet(new Set(selectedAssetIdsSet));
    }
  };

  const toggleAssetSelection = (assetId) => {
    if (selectedAssetIdsSet.has(assetId)) {
      selectedAssetIdsSet.delete(assetId);
      setSelectedAssetIdsSet(new Set(selectedAssetIdsSet));
    } else {
      selectedAssetIdsSet.add(assetId);
      setSelectedAssetIdsSet(new Set(selectedAssetIdsSet));
    }
  };

  const openAssetInfo = (asset) => {
    dispatch(setAssetsToPreviewAction([asset], false, true));
  };

  const navigateToFolder = (folderId, folderName) => {
    if (!folderId) {
      dispatch(getCollectionAssetsAction({
        collectionId: id,
        collectionHash,
      }));
    }

    setSelectedAssetIdsSet(new Set());

    history.push({
      search: folderName ? queryString.stringify({id: folderId, name: folderName}) : '',
    });
  };

  const handleMoveAssets = async (item, folderId = null) => {
    await dispatch(moveCollectionAssetsAction(id, {
      parentId: folderId,
      fileIds: selectedAssetIdsSet.size ? [...selectedAssetIdsSet] : [item.id],
    }, folderFromQuery));
    setSelectedAssetIdsSet(new Set());
  };

  const handleCreateFolder = async (folderName) => {
    await dispatch(createCollectionFolderAction(id, folderName));
    setIsNewFolderModal(false);
  };

  const proceedRenameFolder = async (newFolderName) => {
    await dispatch(editCollectionFolderAction(id, folderToRename.entityId, newFolderName));
    setFolderToRename(null);
  };

  const proceedDeleteAssets = async () => {
    await dispatch(deleteCollectionAssetsAction(id, [...selectedAssetIdsSet], folderFromQuery));
    setIsDeleteAssetsModal(false);
    setSelectedAssetIdsSet(new Set());
  };

  const handleGameClick = (gameId, storageId, dirPath) => {
    const searchQueryString = queryString.stringify({
      search_category: SEARCH_CATEGORY_ENGINES.GAME,
      gameId,
      storageId,
      dirPath: dirPath ? dirPath : ROOT_DIR_PATH,
    });

    history.replace({
      pathname: '/search',
      search: searchQueryString,
    });
  };

  let content;

  if (loading) {
    content = <Preloader />;
  } else if (error) {
    content = <FailRequestScreen message={error} />;
  } else if (!sortedAndFilteredAssets.length) {
    content = (
      <EmptyState
        text={emptyStateText}
        note={!searchValue && canManageCollection ? 'Select files and add them to your collection' : ''}
      >
        {(!searchValue && canManageCollection) &&
          <Link
            to='/search'
            onClick={() => dispatch(presetCollectionIdAction(id))}
            className='collection-storage__add-assets-button'
          >
            <span>select files</span>
          </Link>
        }
      </EmptyState>
    );
  } else if (sortedAndFilteredAssets.length) {
    content = (
      <CollectionStorageResults
        viewType={viewType}
        folders={folders}
        files={files}
        canManageCollection={canManageCollection}
        selectedAssetIdsSet={selectedAssetIdsSet}
        toggleAssetSelection={toggleAssetSelection}
        navigateToFolder={navigateToFolder}
        handleGameClick={handleGameClick}
        handleMoveAssets={handleMoveAssets}
        openAssetInfo={openAssetInfo}
      />
    );
  }

  return (
    <>
      <div ref={collectionStorageRef} className='collection-storage'>
        <div className='collection-storage-panel'>
          <CollectionStorageNavigation
            name={name}
            folderNameFromQuery={folderFromQuery?.name}
            isLegitFolder={isLegitFolder}
            navigateToFolder={navigateToFolder}
            handleMoveAssets={handleMoveAssets}
          />

          <div
            className={classnames('collection-storage-panel__right', {
              'noGap': !canManageCollection || isLegitFolder,
            })}
          >
            <CollectionsActions
              isCollectionPage
              isPrivateCollections={isPrivate}
              selectedCollection={collection}
            />

            <div
              className={classnames('collection-storage-panel__separator', {
                'hidden': !canManageCollection || isLegitFolder,
              })}
            />

            <button
              className={classnames('collection-storage-panel__create-folder-button', {
                'hidden': !canManageCollection || isLegitFolder,
              })}
              onClick={() => setIsNewFolderModal(true)}
            >
              <CreateNewFolderIcon className='icon-inside-button' />
              <span>Create folder</span>
            </button>
          </div>
        </div>

        <div className='collection-storage-assets-panel'>
          <CollectionAssetsActions
            collectionId={id}
            collectionHash={collectionHash}
            canManageCollection={canManageCollection}
            selectedAssetIdsSet={selectedAssetIdsSet}
            selectedAssets={selectedAssets}
            setFolderToRename={setFolderToRename}
            setIsDeleteAssetsModal={setIsDeleteAssetsModal}
          />

          <ButtonWithCheckbox
            text='select all'
            checkedCondition={!!checkIfAllAssetsSelected()}
            toggleCheck={toggleSelectAllAssets}
            disabled={!assets?.length}
          />

          <SortingButton
            sortOptions={ENTITIES_SORT_OPTIONS}
            activeSortOptionId={activeSortOption?.id}
            handleChangeActiveSortOption={handleChangeActiveSortOption}
          />

          <GridListViewPanel
            activeViewType={viewType}
            handleViewTypeChange={toggleViewType}
            newDesign
          />
        </div>

        <div
          className={classnames('collection-storage__content', {
            'info': loading || error || !sortedAndFilteredAssets.length,
          })}
        >
          {content}
        </div>
      </div>

      <RoundModalWithInput
        isOpen={isNewFolderModal}
        title='create folder'
        label='Enter name'
        validationPattern={VALIDATION.ASSET_NAME}
        validationText='Please enter a valid name for folder'
        validationTextForEmptyCase={'Name can\'t contain only spaces'}
        agreeText='Create'
        agreeIcon={<AddIcon className='icon-inside-button' />}
        onCancel={() => setIsNewFolderModal(false)}
        onAgree={handleCreateFolder}
        loading={manageAssetsLoading}
        loadingText='Creating folder'
      />

      <RoundModalWithInput
        isOpen={!!folderToRename}
        title='Rename folder'
        label='Enter new name'
        currentValue={folderToRename?.name}
        validationPattern={VALIDATION.ASSET_NAME}
        validationText='Please enter a valid name for folder'
        validationTextForEmptyCase={'Name can\'t contain only spaces'}
        agreeText='Rename'
        agreeIcon={<EditIcon className='icon-inside-button' />}
        onCancel={() => setFolderToRename(null)}
        onAgree={proceedRenameFolder}
        loading={manageAssetsLoading}
        loadingText='Renaming folder'
      />

      <ConfirmRoundModal
        isOpen={isDeleteAssetsModal}
        title={`Delete asset${selectedAssetIdsSet.size > 1 ? 's' : ''}`}
        text={`Are you sure you want to delete ${selectedAssetIdsSet.size} selected asset${selectedAssetIdsSet.size > 1 ? 's' : ''}?`}
        loading={manageAssetsLoading}
        loadingText={`Deleting asset${selectedAssetIdsSet.size > 1 ? 's' : ''}`}
        onAgree={proceedDeleteAssets}
        onClose={() => setIsDeleteAssetsModal(false)}
      />

      <Selecto
        container={document.body}
        dragContainer={collectionStorageRef?.current}
        selectableTargets={['.file-grid-item, .file-list-item__preview', '.folder-grid-item', '.folder-list-item__preview']}
        selectByClick={false}
        selectFromInside={false}
        continueSelect={false}
        toggleContinueSelect={'shift'}
        keyContainer={window}
        hitRate={100}
        onSelect={(e) => {
          const selectedIds = e.selected.map((el) => Number(el.id));
          setSelectedAssetIdsSet(new Set([...selectedIds, ...Array.from(selectedAssetIdsSet)]));
        }}
        scrollOptions={{
          container: document.body,
          getScrollPosition: () => [
            document.documentElement.scrollLeft,
            document.documentElement.scrollTop,
          ],
        }}
        onScroll={(e) => {
          document.documentElement.scrollBy(
            e.direction[0] * 10,
            e.direction[1] * 10
          );
        }}
      />
    </>
  );
};

CollectionStorage.propTypes = {
  isPrivate: PropTypes.bool,
  collection: collectionPropTypes,
  searchValue: PropTypes.string,
};

export default CollectionStorage;
