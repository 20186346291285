import React from 'react';
import PropTypes from 'prop-types';
import { Zoom } from '@material-ui/core';
import classnames from 'classnames';
import { isBoolean } from 'lodash';
import { ASSET_CATEGORIES_KEYS } from 'constants/Asset';
import StyledTooltip from 'components/UIComponents/StyledTooltip';
import AccordionPanel from 'components/UIComponents/AccordionPanel';
import { str } from 'utils/utils';

import { ReactComponent as CopyIcon } from 'assets/svg/copy.svg';
import { ReactComponent as LinkIcon } from 'assets/svg/link.svg';

import './AssetInfoStatic.scss';

const mainInfoFields = [
  {name: 'Game', key: 'gameName'},
  {name: 'File size', key: 'weight'},
  {name: 'Jira number', key: 'jiraIssueKey'},
  {name: 'Department', key: 'department'},
];

const categoriesInfoFields = [
  {name: 'Asset Category', key: ASSET_CATEGORIES_KEYS.CATEGORY},
  {name: 'Sub-Asset category', key: ASSET_CATEGORIES_KEYS.SUB_CATEGORY},
  {name: 'Production format', key: ASSET_CATEGORIES_KEYS.PRODUCTION_FORMAT},
  {name: 'Developer format', key: ASSET_CATEGORIES_KEYS.DEVELOPER_FORMAT},
];

const jiraInfoFields = [
  {name: 'Jira format', key: 'format'},
  {name: 'Reporter', key: 'reporter'},
  {name: 'Platform', key: 'platform'},
  {name: 'PM', key: 'projectManager'},
  {name: 'Project type', key: 'projectWorkflow'},
  {name: 'Language', key: 'availableLanguage'},
  {name: 'Status', key: 'status'},
  {name: 'Quantity', key: 'quantity'},
  {name: 'Cost', key: 'cost'},
];

const teamInfoFields = [
  {name: 'POD', key: 'pod'},
  {name: 'Assignee', key: 'ownerName'},
];

const gameInfoFields = [
  {name: 'Game category', key: 'level'},
  {name: 'Genre', key: 'genre'},
];

const uaInfoFields = [
  {name: 'Production Tier', key: 'productionTier'},
  {name: 'UA success rate', key: 'successRate'},
  {name: 'Used by UA', key: 'usedByUa'},
  {name: 'Live', key: 'live'},
];

const assetTypeInfoFields = [
  {name: 'Type', key: 'type'},
  {name: 'Orientation', key: 'orientation'},
  {name: 'Size', key: 'size'},
  {name: 'Date added', key: 'createdAt'},
  {name: 'File format', key: 'extension'},
  {name: 'Source file', key: 'sourceFile'},
];


const AssetInfoStatic = ({ asset }) => {

  const displayInfoSections = (infoFields, isClassification) => {
    const result = infoFields.map((infoField, infoFieldIndex) => {

      if (Object.values(ASSET_CATEGORIES_KEYS).includes(infoField.key) && !asset[infoField.key]) {
        return null;
      }

      return (
        <div key={infoFieldIndex} className='asset-info-static__field'>
          <p className='asset-info-static__sub-header'>
            {infoField.name}
          </p>
          <ul
            role='list'
            className='asset-info-static__list'
          >
            {Array.isArray(asset[infoField.key]) ?
              asset[infoField.key].map((info, infoIndex) => (
                <li
                  key={infoIndex}
                  className='asset-info-static__list-item'
                >
                  <p className='asset-info-static__list-item-text'>{info}</p>
                </li>
              )) :
              <li
                className={classnames('asset-info-static__list-item', {
                  'empty': !asset[infoField.key] && !isBoolean(asset[infoField.key]),
                })}
              >
                <p className='asset-info-static__list-item-text'>
                  {isBoolean(asset[infoField.key]) ?
                    asset[infoField.key] ? 'yes' : 'no' :
                    asset[infoField.key] || <span className='asset-info-static__no-data-text'>no data</span>
                  }
                </p>
              </li>
            }
          </ul>
        </div>
      );
    });

    if (isClassification && result.every((item) => item === null)) {
      return <span className='asset-info-static__no-data-text'>no data</span>;
    }

    return result;
  };

  if (!asset) {
    return;
  }

  return (
    <div className='asset-info-static'>
      {mainInfoFields.map((field) => {
        if (asset[field.key]) {
          return (
            <div key={field.name} className='asset-info-static-block'>
              <p className='asset-info-static-block__name'>
                {field.name}
              </p>
              <p className='asset-info-static-block__value'>
                {asset[field.key]}
              </p>
            </div>
          );
        }
      })}

      {asset.jiraIssueLink &&
        <div className='asset-info-static-block'>
          <p className='asset-info-static-block__name'>
            Link to Jira ticket
          </p>
          <p className='asset-info-static-block__value asset-info-static-block__value--icons'>
            <StyledTooltip title='Copy link to Jira ticket' placement='bottom' TransitionComponent={Zoom}>
              <CopyIcon
                className='asset-info-static-block__icon'
                onClick={() => str.copyTextToClipboard(asset.jiraIssueLink)}
              />
            </StyledTooltip>
            <StyledTooltip title='Open Jira ticket' placement='bottom' TransitionComponent={Zoom}>
              <a
                rel='noreferrer'
                className='asset-info-static-block__link-icon'
                href={asset.jiraIssueLink}
                target={'_blank'}
              >
                <LinkIcon className='asset-info-static-block__icon' />
              </a>
            </StyledTooltip>
          </p>
        </div>
      }

      {asset.description &&
        <div className='asset-info-static-block'>
          <p className='asset-info-static-block__name'>
            description
          </p>
          <p className='asset-info-static-block__value'>
            {asset.description}
          </p>
        </div>
      }

      <AccordionPanel
        title='Asset categories'
        additionalClassName='asset-info-static-accordion'
        defaultExpanded
      >
        <div className='asset-info-static-accordion__content'>
          {displayInfoSections(categoriesInfoFields, true)}
        </div>
      </AccordionPanel>

      {asset.jiraIssueLink &&
        <AccordionPanel
          title='Jira project data'
          additionalClassName='asset-info-static-accordion'
          defaultExpanded
        >
          <div className='asset-info-static-accordion__content'>
            {displayInfoSections(jiraInfoFields)}
          </div>
        </AccordionPanel>
      }

      <AccordionPanel
        title='Team'
        additionalClassName='asset-info-static-accordion'
        defaultExpanded
      >
        <div className='asset-info-static-accordion__content'>
          {displayInfoSections(teamInfoFields)}
        </div>
      </AccordionPanel>

      <AccordionPanel
        title='Game info'
        additionalClassName='asset-info-static-accordion'
        defaultExpanded
      >
        <div className='asset-info-static-accordion__content'>
          {displayInfoSections(gameInfoFields)}
        </div>
      </AccordionPanel>

      <div className='asset-info-static-block'>
        <p className='asset-info-static-block__name'>
          CAT Asset type
        </p>
        <p className='asset-info-static-block__value'>
          {asset.CATAssetType ?
            asset.CATAssetType :
            <span className='asset-info-static__no-data-text'>no data</span>
          }
        </p>
      </div>

      <AccordionPanel
        title='UA/aso Info'
        additionalClassName='asset-info-static-accordion'
        defaultExpanded
      >
        <div className='asset-info-static-accordion__content'>
          {displayInfoSections(uaInfoFields)}
        </div>
      </AccordionPanel>

      <AccordionPanel
        title='Asset Type'
        additionalClassName='asset-info-static-accordion'
        defaultExpanded
      >
        <div className='asset-info-static-accordion__content'>
          {displayInfoSections(assetTypeInfoFields)}
        </div>
      </AccordionPanel>
    </div>
  );
};

AssetInfoStatic.propTypes = {
  asset: PropTypes.object.isRequired,
};

export default AssetInfoStatic;
