import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Selecto from 'react-selecto';
import PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router-dom';
import RoundModalWithInput from '../../UIComponents/RoundModalWithInput';
import EmptyState from '../../UIComponents/EmptyState';
import Preloader from '../../UIComponents/Preloader';
import FailRequestScreen from '../../UIComponents/FailRequestScreen';
import CreativeStorageNavigation from './CreativeStorageNavigation';
import CreativeStorageAssetActions from './CreativeStorageAssetActions';
import CreativeStorageResults from './CreativeStorageResults';
import { getStorageData, renameAssetAction } from '../../Assets/Assets.redux/Actions/assetActions';
import { setAssetsToPreviewAction } from '../../Assets/AssetsDetailsPreview/AssetsDetailsPreview.redux/actions';
import { handleSortEntities } from '../../MainSearch/mainSearchHelpers';
import { VIEW_GRID } from 'constants/General';
import { VALIDATION } from 'constants/Validation';
import { ENTITIES_SORT_OPTIONS } from '../../MainSearch/mainSearchConstants';

import EditIcon from '@material-ui/icons/Edit';

import './CreativeStorage.scss';


const CreativeStorage = ({
  creativePageRef,
  game,
  jiraIssueStatus,
  canManageAssets,
  selectedAssetIdsSet,
  setSelectedAssetIdsSet,
  handleMoveAssets,
  handleGameClick,
}) => {

  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const { id: gameId, name: gameName } = game || {};

  const {
    isFetching: loadingStorage,
    error: errorGetStorage,
    creativeStorageData: storageAssets,
    currentTab,
  } = useSelector((state) => state.creatives);

  const {
    isFetching: loadingAssets,
  } = useSelector((state) => state.assets);

  const [folderToRename, setFolderToRename] = useState(null);
  const [viewType, setViewType] = useState(VIEW_GRID);
  const [activeSortOption, setActiveSortOption] = useState(ENTITIES_SORT_OPTIONS[0]);
  const sortedStorageAssets = useMemo(() => storageAssets ? handleSortEntities(storageAssets, activeSortOption) : [], [storageAssets, activeSortOption.id]);
  const folders = useMemo(() => sortedStorageAssets.filter((asset) => asset.isFolder), [sortedStorageAssets]);
  const files = useMemo(() => sortedStorageAssets.filter((asset) => !asset.isFolder), [sortedStorageAssets]);
  const selectedAssets = useMemo(
    () => sortedStorageAssets.filter((asset) => selectedAssetIdsSet.has(asset.id)),
    [sortedStorageAssets, selectedAssetIdsSet]
  );

  const searchParams = new URLSearchParams(location.search);
  const storageId = useMemo(() => Number(searchParams.get('storageId')), [searchParams]);
  const dirPath = useMemo(() => searchParams.get('dirPath'), [searchParams]);

  useEffect(() => {
    setSelectedAssetIdsSet(new Set());
  }, []);

  useEffect(() => {
    if (!isNaN(storageId)) {
      dispatch(getStorageData(storageId, dirPath));
      setSelectedAssetIdsSet(new Set());
    }
  }, [storageId, dirPath]);

  const toggleAssetSelection = (assetId) => {
    if (selectedAssetIdsSet.has(assetId)) {
      selectedAssetIdsSet.delete(assetId);
      setSelectedAssetIdsSet(new Set(selectedAssetIdsSet));
    } else {
      selectedAssetIdsSet.add(assetId);
      setSelectedAssetIdsSet(new Set(selectedAssetIdsSet));
    }
  };

  const proceedRenameFolder = async (newFolderName) => {
    const {
      id,
      folderPath,
      storageId,
    } = folderToRename;

    const data = {
      filePath: folderPath ? `${folderPath}/${newFolderName}` : newFolderName,
      docId: id,
      storageId,
    };

    await dispatch(renameAssetAction(data));
    await dispatch(getStorageData(storageId, dirPath));

    setFolderToRename(null);
  };

  const navigateToPath = (neededPath) => {
    history.push({
      pathname: location.pathname,
      search: new URLSearchParams({
        storageId: currentTab.storageId,
        dirPath: neededPath,
      }).toString(),
    });
  };

  const navigateToFolder = (folderName) => {
    navigateToPath(dirPath ? `${dirPath}/${folderName}` : folderName);
  };

  const openAssetInfo = (asset) => {
    dispatch(setAssetsToPreviewAction([asset], true));
  };

  let content;

  if (loadingStorage) {
    content = <Preloader additionalClass='creative-storage__loader' />;
  } else if (errorGetStorage) {
    content = <FailRequestScreen message={errorGetStorage} additionalClass='creative-storage__error' />;
  } else  {
    content = (
      <>
        <CreativeStorageNavigation
          dirPath={dirPath}
          navigateToPath={navigateToPath}
          handleMoveAssets={handleMoveAssets}
        />

        <CreativeStorageAssetActions
          gameId={gameId}
          currentTabName={currentTab.name?.toUpperCase()}
          jiraIssueStatus={jiraIssueStatus}
          viewType={viewType}
          setViewType={setViewType}
          activeSortOptionId={activeSortOption.id}
          setActiveSortOption={setActiveSortOption}
          storageAssets={storageAssets}
          selectedAssets={selectedAssets}
          selectedAssetIdsSet={selectedAssetIdsSet}
          setSelectedAssetIdsSet={setSelectedAssetIdsSet}
          setFolderToRename={setFolderToRename}
          getStorage={() => dispatch(getStorageData(storageId, dirPath))}
        />

        {!storageAssets.length ?
          <div className='creative-storage__empty-state'>
            <EmptyState
              text='Oops! Nothing was found'
              note='No files/folders have been created yet'
            />
          </div> :
          <CreativeStorageResults
            viewType={viewType}
            gameName={gameName}
            folders={folders}
            files={files}
            selectedAssetIdsSet={selectedAssetIdsSet}
            toggleAssetSelection={toggleAssetSelection}
            navigateToFolder={navigateToFolder}
            canManageAssets={canManageAssets}
            handleMoveAssets={handleMoveAssets}
            handleGameClick={handleGameClick}
            openAssetInfo={openAssetInfo}
          />
        }
      </>
    );
  }

  return (
    <>
      <div className='creative-storage'>
        {content}
      </div>

      <RoundModalWithInput
        isOpen={!!folderToRename}
        title='Rename folder'
        label='Enter new name'
        currentValue={folderToRename?.name}
        validationPattern={VALIDATION.ASSET_NAME}
        validationText='Please enter a valid name for folder'
        validationTextForEmptyCase={'Name can\'t contain only spaces'}
        agreeText='Rename'
        agreeIcon={<EditIcon className='icon-inside-button' />}
        onCancel={() => setFolderToRename(null)}
        onAgree={proceedRenameFolder}
        loading={loadingAssets}
        loadingText='Renaming folder'
      />

      <Selecto
        container={document.body}
        dragContainer={creativePageRef?.current}
        selectableTargets={['.file-grid-item, .file-list-item__preview', '.folder-grid-item', '.folder-list-item__preview']}
        selectByClick={false}
        selectFromInside={false}
        continueSelect={false}
        toggleContinueSelect={'shift'}
        keyContainer={window}
        hitRate={100}
        onSelect={(e) => {
          const selectedIds = e.selected.map((el) => Number(el.id));
          setSelectedAssetIdsSet(new Set([...selectedIds, ...Array.from(selectedAssetIdsSet)]));
        }}
        scrollOptions={{
          container: document.body,
          getScrollPosition: () => [
            document.documentElement.scrollLeft,
            document.documentElement.scrollTop,
          ],
        }}
        onScroll={(e) => {
          document.documentElement.scrollBy(
            e.direction[0] * 10,
            e.direction[1] * 10
          );
        }}
      />
    </>
  );
};

CreativeStorage.propTypes = {
  creativePageRef: PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  game: PropTypes.object,
  jiraIssueStatus: PropTypes.string,
  canManageAssets: PropTypes.bool,
  selectedAssetIdsSet: PropTypes.object.isRequired,
  setSelectedAssetIdsSet: PropTypes.func.isRequired,
  handleMoveAssets: PropTypes.func.isRequired,
  handleGameClick: PropTypes.func.isRequired,
};

export default CreativeStorage;
