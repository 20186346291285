export const RESULTS_PER_PAGE = 40;
export const MAX_FACET_SIZE = 250;
export const MAX_ASSETS_TO_SHARE = 200;

export const ROOT_DIR_PATH = 'r00tD1rP@ath3589';
export const MAIN_SEARCH_FILTERS_LOCAL_STORAGE_KEY = 'PixelMainSearchFilters-v1';
export const MAIN_SEARCH_VIEW_TYPE_LOCAL_STORAGE_KEY = 'PixelMainSearchViewType';
export const MAIN_SEARCH_PAGINATION_LOCAL_STORAGE_KEY = 'PixelMainSearchPagination';
export const MAIN_SEARCH_SORTING_LOCAL_STORAGE_KEY = 'PixelMainSearchSortOption';
export const MAIN_SEARCH_CONCLUDED_GAMES_LOCAL_STORAGE_KEY = 'PixelMainSearchConcludedGames';
export const MAIN_SEARCH_ACTIVE_GAMES_LOCAL_STORAGE_KEY = 'PixelMainSearchActiveGames';
export const LAST_ONE_ENGINE_KEY = 'lastOneActiveSearchEngineKey';

export const ENTITIES_SORT_FIELD = {
  CREATED_AT: 'created_at',
  NAME: 'name',
};

export const ENTITIES_SORT_ORDER = {
  DESC: 'desc',
  ASC: 'asc',
};

export const ENTITIES_SORT_OPTIONS = [
  {id: 1, name: 'Date (NEWEST - OLDEST)', value: ENTITIES_SORT_FIELD.CREATED_AT, order: ENTITIES_SORT_ORDER.DESC},
  {id: 2, name: 'Date (OLDEST - NEWEST)', value: ENTITIES_SORT_FIELD.CREATED_AT, order: ENTITIES_SORT_ORDER.ASC},
  {id: 3, name: 'Name (A to Z)', value: ENTITIES_SORT_FIELD.NAME, order: ENTITIES_SORT_ORDER.ASC},
  {id: 4, name: 'Name (Z to A)', value: ENTITIES_SORT_FIELD.NAME, order: ENTITIES_SORT_ORDER.DESC},
];
