import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactPlayer from 'react-player';
import EntityDoNotUseOverlay from '../../Entities/Parts/EntityDoNotUseOverlay';
import Controls from './Controls';
import { FRAMES_PER_SECOND } from 'constants/General';

import styles from './styles.module.scss';

class Player extends Component {
  constructor(props) {
    super(props);

    this.state = {
      playing: props.play,
      volume: 0.4,
      muted: false,
      duration: 0,
      playbackRate: 1.0,
      loop: false,
      timeValue: 0,
      playedSeconds: 0,
    };

    this.playerWrapper = React.createRef();
  }

  componentWillUnmount() {
    const { onVideoProgress } = this.props;

    if (onVideoProgress) {
      onVideoProgress(undefined);
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.pauseState !== this.props.pauseState) {
      if (this.props.pauseState) {
        this.onPause();
      } else {
        this.onPlay();
      }
    }
  }

  togglePlay = () => {
    this.setState((state) => ({ playing: !state.playing }));
  };

  setVolume = (volumeValue) => {
    this.setState({ volume: volumeValue });
  };

  onMute = () => {
    this.setState({ muted: !this.state.muted });
  };

  onPlay = () => {
    this.setState({ playing: true });
  };

  onPause = () => {
    this.setState({ playing: false });
  };

  onProgress = (state) => {
    const { onVideoProgress } = this.props;
    const { seeking } = this.state;

    if (!seeking) {
      this.setState(state);
      this.seekTo(state.playedSeconds * 1000);
    }

    if (onVideoProgress) {
      onVideoProgress(state.playedSeconds);
    }
  };

  onEnded = () => {
    this.setState((state) => ({ playing: state.loop }));
  };

  onDuration = (duration) => {
    this.setState({ duration });
  };

  seekUp = (v) => {
    this.player.seekTo(v / 1000);
  };

  seekTo = (timeValue) => {
    this.setState({ timeValue });
  };

  onFullscreen = () => {
    const elem = this.playerWrapper.current;

    if (!document.fullscreenElement) {
      elem.requestFullscreen().catch((err) => {
        alert(`Error attempting to enable fullscreen mode: ${err.message} (${err.name})`);
      });
    } else {
      document.exitFullscreen();
    }
  };

  onDisplayClickHandler = (e) => {
    const { fullscreen } = this.state;
    const { onDisplayClick } = this.props;

    if (fullscreen || !onDisplayClick) {
      this.togglePlay(e);
    } else {
      onDisplayClick();
    }
  };

  render() {
    const {
      playing,
      volume,
      timeValue,
      muted,
      loop,
      playedSeconds,
      duration,
      playbackRate,
    } = this.state;

    const eachFrame = 1 / FRAMES_PER_SECOND;

    return (
      <div
        ref={this.playerWrapper}
        className={styles.playerContainer}
      >
        <div
          className={styles.playerClickWrapper}
          onClick={this.onDisplayClickHandler}
        >
          <ReactPlayer
            ref={(player) => this.player = player}
            width='inherit'
            height='inherit'
            style={{
              minWidth: 'inherit',
              maxWidth: 'inherit',
              maxHeight: 'inherit',
              minHeight: 'inherit',
            }}
            url={this.props.url}
            playing={playing}
            loop={loop}
            playbackRate={playbackRate}
            progressInterval={eachFrame}
            volume={volume}
            muted={muted}
            onReady={this.props.onReady}
            onError={this.props.onError}
            onPlay={this.onPlay}
            onPause={this.onPause}
            onEnded={this.onEnded}
            onProgress={this.onProgress}
            onDuration={this.onDuration}
          />
          {this.props.notApproved &&
            <EntityDoNotUseOverlay iconFontSize={76} />
          }
        </div>
        <Controls
          duration={duration}
          playing={playing}
          playedSeconds={playedSeconds}
          volume={volume}
          muted={muted}
          rangeValue={timeValue}
          onFullscreen={this.onFullscreen}
          onPlay={this.onPlay}
          onPause={this.onPause}
          setVolume={this.setVolume}
          onMute={this.onMute}
          seekTo={this.seekTo}
          seekUp={this.seekUp}
        />
      </div>
    );
  }
}

Player.propTypes = {
  url: PropTypes.string.isRequired,
  play: PropTypes.bool,
  pauseState: PropTypes.bool,
  notApproved: PropTypes.bool,
  onDisplayClick: PropTypes.func,
  onVideoProgress: PropTypes.func,
  onReady: PropTypes.func,
  onError: PropTypes.func,
};

Player.defaultProps = {
  onVideoProgress: undefined,
};

export default Player;
