import React from 'react';
import PropTypes from 'prop-types';
import FileListItem from 'components/Entities/File/FileListItem';
import FolderListItem from 'components/Entities/Folder/FolderListItem';
import { ASSET_DRAG_AND_DROP_ACCEPT_TYPE, ASSET_TYPE } from 'constants/Asset';
import { str } from 'utils/utils';

import './CreativeStorageListView.scss';

const tableTitles = ['', 'Preview', 'live', 'Name', 'Extension', 'Date creation', 'Size'];


const CreativeStorageListView = ({
  folders,
  files,
  selectedAssetIdsSet,
  toggleAssetSelection,
  navigateToFolder,
  canManageAssets,
  handleMoveAssets,
  openAssetInfo,
}) => {
  return (
    <div className='creative-storage-list-view'>

      <p className='creative-storage-list-view__title'>Folders & Files</p>

      <div className='creative-storage-list-view-table'>
        <div className='creative-storage-list-view-table__header'>
          {tableTitles.map((title, titleIndex) => (
            <p key={titleIndex} className='creative-storage-list-view-table__title'>
              {title}
            </p>
          ))}
        </div>

        <div className='creative-storage-list-view-table__body'>
          {folders.map((folder) => (
            <FolderListItem
              key={folder.id}
              id={folder.id}
              name={folder.name}
              path={folder.path}
              createdAt={folder.createdAt}
              isSelected={selectedAssetIdsSet.has(folder.id)}
              toggleSelection={() => toggleAssetSelection(folder.id)}
              navigateToFolder={() => navigateToFolder(folder.name)}
              userCanManage={canManageAssets}
              dragAndDropAcceptType={ASSET_DRAG_AND_DROP_ACCEPT_TYPE.CREATIVE}
              onDropAssets={handleMoveAssets}
            />
          ))}

          {files.map((file) => (
            <FileListItem
              key={file.id}
              id={file.id}
              name={file.name}
              extension={file.extension}
              createdAt={file.createdAt}
              weight={file.weight}
              live={file.live}
              approved={file.approved}
              isSelected={selectedAssetIdsSet.has(file.id)}
              previewUrl={file.smallPreviewUrl ?? str.getExtensionIconSrc(file.extension)}
              hasPreview={!!file.smallPreviewUrl}
              hasVideoPreview={file.fileType === ASSET_TYPE.VIDEO}
              toggleSelection={() => toggleAssetSelection(file.id)}
              userCanManage={canManageAssets}
              dragAndDropAcceptType={ASSET_DRAG_AND_DROP_ACCEPT_TYPE.CREATIVE}
              openFileInfo={() => openAssetInfo(file)}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

CreativeStorageListView.propTypes = {
  folders: PropTypes.arrayOf(PropTypes.object),
  files: PropTypes.arrayOf(PropTypes.object),
  selectedAssetIdsSet: PropTypes.object,
  toggleAssetSelection: PropTypes.func.isRequired,
  navigateToFolder: PropTypes.func.isRequired,
  canManageAssets: PropTypes.bool,
  handleMoveAssets: PropTypes.func.isRequired,
  openAssetInfo: PropTypes.func.isRequired,
};

export default CreativeStorageListView;
