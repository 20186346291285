import React from 'react';
import PropTypes from 'prop-types';
import FileListItem from 'components/Entities/File/FileListItem';
import FolderListItem from 'components/Entities/Folder/FolderListItem';
import {
  ASSET_DRAG_AND_DROP_ACCEPT_TYPE,
  ASSET_TYPE,
} from 'constants/Asset';
import { str } from 'utils/utils';

import './CollectionStorageListView.scss';

const tableTitles = ['', 'Preview', 'live', 'Name', 'Extension', 'Game', 'Jira ticket', 'Date creation', 'Size'];


const CollectionStorageListView = ({
  folders,
  files,
  canManageCollection,
  selectedAssetIdsSet,
  toggleAssetSelection,
  navigateToFolder,
  handleGameClick,
  handleMoveAssets,
  openAssetInfo,
}) => {
  return (
    <div className='collection-storage-list-view'>

      <p className='collection-storage-list-view__title'>Folders & Files</p>

      <div className='collection-storage-list-view-table'>
        <div className='collection-storage-list-view-table__header'>
          {tableTitles.map((title, titleIndex) => (
            <p key={titleIndex} className='collection-storage-list-view-table__title'>
              {title}
            </p>
          ))}
        </div>

        <div className='collection-storage-list-view-table__body'>
          {folders.map((folder) => (
            <FolderListItem
              key={folder.entityId}
              id={folder.entityId}
              name={folder.name}
              createdAt={folder.createdAt}
              isSelected={selectedAssetIdsSet.has(folder.entityId)}
              toggleSelection={() => toggleAssetSelection(folder.entityId)}
              navigateToFolder={() => navigateToFolder(folder.entityId, folder.name)}
              userCanManage={canManageCollection}
              dragAndDropAcceptType={ASSET_DRAG_AND_DROP_ACCEPT_TYPE.COLLECTION}
              onDropAssets={handleMoveAssets}
              isCollectionFolder
            />
          ))}

          {files.map((file) => (
            <FileListItem
              key={file.entityId}
              id={file.entityId}
              name={file.name}
              extension={file.extension}
              gameName={file.gameName}
              jiraTicketName={file.jiraTicketName}
              jiraTicketLink={file.jiraTicketLink}
              createdAt={file.createdAt}
              weight={file.weight}
              live={file.live}
              approved={file.approved}
              isSelected={selectedAssetIdsSet.has(file.entityId)}
              previewUrl={file.smallPreviewUrl ?? str.getExtensionIconSrc(file.extension)}
              hasPreview={!!file.smallPreviewUrl}
              hasVideoPreview={file.fileType === ASSET_TYPE.VIDEO}
              toggleSelection={() => toggleAssetSelection(file.entityId)}
              handleGameClick={() => handleGameClick(
                file.gameId,
                file.creativeId ? file.gameStorageId : file.storageId,
                !file.creativeId ? file.folderPath : null
              )}
              userCanManage={canManageCollection}
              dragAndDropAcceptType={ASSET_DRAG_AND_DROP_ACCEPT_TYPE.COLLECTION}
              openFileInfo={() => openAssetInfo(file)}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

CollectionStorageListView.propTypes = {
  folders: PropTypes.arrayOf(PropTypes.object),
  files: PropTypes.arrayOf(PropTypes.object),
  canManageCollection: PropTypes.bool,
  selectedAssetIdsSet: PropTypes.object,
  toggleAssetSelection: PropTypes.func.isRequired,
  navigateToFolder: PropTypes.func.isRequired,
  handleGameClick: PropTypes.func.isRequired,
  handleMoveAssets: PropTypes.func.isRequired,
  openAssetInfo: PropTypes.func.isRequired,
};

export default CollectionStorageListView;
